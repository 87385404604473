<template>
  <items-list :component="PostSingle" :items="posts" list-page-slug="blog">
    <template #title>
      {{ data.title }}
    </template>

    <template #button>
      <app-button
        v-if="data.button"
        :page="data.button.page.data"
        :url="data.button.url"
        class="button"
      >
        {{ data.button.text }}
      </app-button>
    </template>

    <template v-if="pagination && showPagination" #pagination>
      <app-pagination
        :page="pagination.page"
        :pages-count="pagination?.pageCount"
        path="blog"
      />
    </template>
  </items-list>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";
import { useErrorTracking } from "~/modules/error/composables/useErrorTracking";
import PostSingle from "~/modules/posts/components/PostSingle.vue";
import type { PostsDto } from "~/modules/posts/ts/dto/PostsDto";
import type { Post } from "~/modules/posts/ts/Post";
import AppButton from "~/modules/shared/components/AppButton.vue";
import AppPagination from "~/modules/shared/components/AppPagination.vue";
import ItemsList from "~/modules/shared/components/ItemsList/ItemsList.vue";
import { CLIENT_ERROR_SLUGS } from "~/modules/shared/constants/ClientErrorSlugs";
import type { PostsList } from "~/modules/shared/ts/pageComponents/PostsList";
import type { Pagination } from "~/modules/shared/ts/Pagination";
import type { StrapiLocale } from "~/node_modules/@nuxtjs/strapi/dist/runtime/types";

const props = defineProps<{
  data: PostsList;
}>();

const { locale } = useI18n();

const { find } = useStrapi4();
const { trackError } = useErrorTracking();
const pagination: Ref<Pagination | undefined> = ref();
const posts: Ref<Post[]> = ref([]);
const route = useRoute();

const showPagination = computed(() => (pagination.value?.pageCount || 0) > 1);

const fetchPosts = (category?: string | undefined) =>
  useAsyncData("posts", () =>
    find<PostsDto>("posts", {
      fields: ["createdAt", "updatedAt", "title", "slug"],
      locale: locale.value as StrapiLocale,
      ...(category
        ? {
            filters: {
              categories: {
                slug: {
                  ["$contains"]: category,
                },
              },
            },
          }
        : {}),
      pagination: {
        pageSize: props.data.per_page,
        page: pagination.value?.page || Number(route.params.page) || 1,
      },
      populate: ["categories", "image", "tags"],
      sort: ["publishedAt"],
    }),
  );

try {
  if (props.data.posts.data.length) {
    posts.value = props.data.posts.data;
  } else {
    const { data, error } = await fetchPosts();

    if (error.value) throw error;

    pagination.value = data.value?.meta.pagination;
    posts.value = data.value?.data || [];
  }
} catch (err) {
  trackError(CLIENT_ERROR_SLUGS.POSTS_LIST_INIT, err);
}

watch(
  () => route.query?.category,
  async () => {
    try {
      const { data, error } = await fetchPosts(
        route.query.category as string | undefined,
      );

      if (error.value) throw error;

      pagination.value = data.value?.meta.pagination;
      posts.value = data.value?.data || [];
    } catch (err) {
      trackError(CLIENT_ERROR_SLUGS.POSTS_LIST_INIT, err);
    }
  },
);
</script>
