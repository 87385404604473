<template>
  <item-single :url="postUrl">
    <template #image>
      <img
        v-if="data.attributes.image.data"
        :alt="data.attributes.image.data.attributes.alternativeText"
        :src="useImageUrl(data.attributes.image, 'small')"
        class="image"
        loading="lazy"
      />
    </template>

    <template #caption>
      <p class="caption__tag item__date">
        {{ useDate(data.attributes.createdAt, "DD-MM-YYYY") }}
      </p>

      <p :title="postCategories" class="caption__tag item__category">
        <span class="gradient-text">
          {{ postCategories }}
        </span>
      </p>
    </template>

    <template #title>
      {{ data.attributes.title }}
    </template>

    <template #link-text>Read More</template>
  </item-single>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import type { Post } from "~/modules/posts/ts/Post";
import ItemSingle from "~/modules/shared/components/Item/ItemSingle.vue";

import { useDate } from "~/modules/shared/composables/useDate";
import { useImageUrl } from "~/modules/shared/composables/useImageUrl";

const props = defineProps<{
  data: Post;
  listPageSlug: string | undefined;
}>();
const postCategories = computed(() => {
  return props.data.attributes.categories.data
    .map(({ attributes }) => attributes.name)
    .join(", ");
});
const postUrl = computed(
  () => `${props.listPageSlug}/${props.data.attributes.slug}`,
);
</script>

<style lang="scss" scoped>
.image {
  @apply w-full;
}

.caption__tag {
  @apply p-3 bg-primary-75 text-body-xxs;
  border-radius: 0.4rem;
}

.item__date {
  @apply md:mr-3 font-medium whitespace-nowrap;
}

.item__category {
  @apply uppercase whitespace-nowrap overflow-hidden;
  text-overflow: ellipsis;
}
</style>
